.my-color-picker {
  height: 100%;
  overflow: hidden;
  position: relative;

  .color-selector {
    display: inline-block;
    margin: 0 auto;
    border-radius: 3px;
    position: relative;
    padding: 6px 32px 6px 10px;
    background: white;
    border: 1px solid #1876d2;

    &::after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 5px 0 5px;
      border-color: black transparent transparent transparent;
      position: absolute;
      right: 10px;
      top: 17px;
    }

    .circle {
      display: inline-block;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 1px solid #eee;
      margin-right: 10px;
    }

    .hidden {
      position: absolute;
      left: 0;
      opacity: 0;
    }

    span {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .overlay {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 105px;
    transition: 1s;

    &.active {
      top: calc(-50% + 105px);
      width: 160%;
      height: 160%;
      left: -30%;
    }
  }
}

