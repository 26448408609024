.react-resizable {
    position: relative;
}

.react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
    background-position: bottom right;
    padding: 0 3px 3px 0;
}

.react-resizable-handle-sw {
    bottom: 0;
    left: 0;
    cursor: sw-resize;
    transform: rotate(90deg);
}

.react-resizable-handle-se {
    bottom: 0;
    right: 0;
    cursor: se-resize;
}

.react-resizable-handle-nw {
    top: 0;
    left: 0;
    cursor: nw-resize;
    transform: rotate(180deg);
}

.react-resizable-handle-ne {
    top: 0;
    right: 0;
    cursor: ne-resize;
    transform: rotate(270deg);
}

.react-resizable-handle-w,
.react-resizable-handle-e {
    top: 50%;
    margin-top: -10px;
    cursor: ew-resize;
}

.react-resizable-handle-w {
    left: 0;
    transform: rotate(135deg);
}

.react-resizable-handle-e {
    right: 0;
    transform: rotate(315deg);
}

.react-resizable-handle-n,
.react-resizable-handle-s {
    left: 50%;
    margin-left: -10px;
    cursor: ns-resize;
}

.react-resizable-handle-n {
    top: 0;
    transform: rotate(225deg);
}

.react-resizable-handle-s {
    bottom: 0;
    transform: rotate(45deg);
}

.layoutRoot {
    display: flex;
    width: 100%;

    .app-criteria-icon {
        margin: 4px auto;
        border-radius: 5px;
        width: 50%;
        max-width: 50px;
        user-select: none;
        user-drag: none;
        -webkit-user-drag: none;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
    }
}

.skin5-bottom-actions {
    display: flex;
    justify-content: space-between;
    margin: 20px 0 0 0;

    .hidden-elements {
        display: flex;
        justify-content: space-between;

        h3 {
            text-align: left;
            margin-bottom: 4px;
            display: none;
        }

        .hidden-elements-item {
            text-align: right;
            font-size: 14px;
            margin-right: 16px;
            cursor: pointer;
        }
    }

    .skin-5-app-actions {
display: flex;
        align-items: center;
    }
}


.importance-card-title {
    border-bottom: 1px solid grey;

    & svg {
        fill: black;
        width: 16px;
        height: 16px;
    }
}

.box {
    border: 1px solid #ccc;
    margin-right: 8px;
    overflow: hidden;

    height: 250px;
    border-radius: 5px;
    display: flex;
    padding-top: 8px;
    flex-direction: column;
    align-items: center;
    user-select: none;
    background-color: white;
}