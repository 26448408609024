@import 'variables';
@import 'mixins';

body {
  margin: 0;
}

* {
  font-family: $primary-font;
  font-weight: 400;
}
