.application {
  width: 90%;
  max-width: 1600px;
  margin: 0 auto 32px;

  @media screen and (max-width: 800px) {
    width: 100% !important;
    padding: 0 16px;
    margin-bottom: 100px !important;

    .application-details {
      flex-direction: column;

      .app-criteria {
        width: 100% !important;
        margin-bottom: 20px;
      }
    }
  }

  .warning-banner {
    padding: 10px;
    background-color: #f5d69e;
    margin-bottom: 16px;
  }

  .application-data {
    display: flex;
    margin-bottom: 32px;

    @media screen and (max-width: 800px) {
      flex-direction: column;

      img {
        width: 100% !important;
        max-width: 300px;
      }
    }

    img {
      width: 250px;
    }

    .app-info {
      margin-left: 16px;

      .app-name {
        margin-bottom: 8px;
      }

      .app-description {
        color: #676767;
      }
    }
  }

  .application-details {
    display: flex;

    .app-criteria {
      width: 25%;

      .app-criteria-actions {
        margin-top: 8px;
        display: flex;
        justify-content: space-between;

        .app-criteria-actions-action {
          width: 49%;
        }
      }
    }

    .app-results {
      width: 75%;
      padding-left: 32px;

      h3 {
        margin-bottom: 16px;
      }

      .results-skin1 {
        display: flex;
      }
    }
  }

  .app-criteria-item-details-ctas-cta {
    span {
      margin: 0;
    }
  }

  .truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .product-link {
    cursor: pointer;
    font-weight: bold;

    &:hover {
      text-decoration: underline;
    }
  }

  .results-skin1 {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 20px;

    @media screen and (max-width: 800px) {
      flex-direction: column;

      .results-skin1-left {
        width: 100%;
        margin-top: 32px;

        &-app-settings-item {
          flex-direction: column;

          & > .left {
            width: 100% !important;

            .sub-title {
              display: none;
            }
          }

          & > .right {
            width: 100% !important;
          }

          .multi-select-settings {
            flex-direction: column;

            &-item {
              //flex-direction: column;
              //align-items: flex-start!important;
              width: 100% !important;

              & > div.custom-slider-label {
                max-width: unset !important;
              }

              & > div.custom-slider {
                //width: 100% !important;
              }
            }
          }
        }
      }

      .results-skin1-right {
        width: 100%;
      }
    }

    &-left {
      width: 70%;

      .results-skin1-left-app-settings {
        width: 95%;

        &-item {
          display: flex;
          box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.75);
          padding: 16px 20px;
          border-radius: 4px;
          margin-bottom: 16px;
          justify-content: space-between;

          .left {
            width: 20%;
            min-width: 100px;
            max-width: 200px;
            display: flex;
            flex-direction: column;
            //justify-content: space-around;

            .title {
              font-weight: 600;
            }

            .sub-title {
              margin-top: 40px;
              font-size: 12px;
            }
          }

          .right {
            width: 75%;

            .multi-select-settings {
              flex-wrap: wrap;
              justify-content: space-between;
              //font-size: 'small';
              align-items: center;

              &-item {
                display: flex;
                align-items: center;
                justify-content: space-between;

                & > div.custom-slider-label {
                  font-size: 14px;
                  max-width: 90px;
                }

                & > div.custom-slider {
                  width: 60%;
                }
              }
            }
          }
        }
      }
    }

    &-right {
      width: 28%;

      &-first-result {
        &-image {
          width: 100%;
          max-width: 300px;
        }

        &-title {
          font-size: 18px;
          font-weight: 600;
          width: 90%;
          max-width: 400px;
        }

        &-specs {
          transition: all ease-in-out 0.5s;

          .spec {
            display: flex;

            .spec-name {
              font-size: 14px;
              width: 120px;
            }

            .spec-value {
              font-size: 14px;
            }
          }
        }
      }

      &-results {
        width: 100%;
        min-width: 320px;

        &-title {
          margin-top: 20px;
          font-size: 20px;
          font-weight: 500;
        }

        &-item {
          display: flex;
          justify-content: flex-start;
          margin-top: 4px;
          padding: 8px 12px;
          border-radius: 4px;
          transition: all ease .3s;
          cursor: pointer;

          &:hover {
            box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.75);
          }

          .text {
            min-width: 200px;
            width: 70%;
            margin-left: 16px;
          }

          .rank {
            display: flex;
            align-items: center;
            margin-left: 16px;

            .arrow-up {
              cursor: pointer;
              margin-left: 4px;
            }

          }
        }
      }
    }
  }

  .app-actions {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    position: fixed;
    bottom: 0;
    left: 0;
    background: #f5f5f5;
    text-align: center;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.75);

    @media screen and (max-width: 800px) {
      .app-actions-mode-buttons {
        display: block;
        text-align: center;
        margin-bottom: 8px;
      }
    }
  }
}

.hidden {
  display: none;
}

.results-app-settings-item {
  margin-bottom: 30px;

  .left {
    margin-bottom: 16px;

    .title {
      font-size: 18px;
      font-weight: 500;
    }
  }
}

.old-app-description {
  margin-top: 6px;
  color: #676767;
  font-size: 14px;
  padding: 8px;

  & > a {
    color: #676767;
  }
}

.skin6 {
  .application-details {
    flex-direction: column;
    align-items: center;

    .app-criteria {
      width: 100% !important;
    }

    .app-criteria-draggable-cards {
      margin-bottom: 64px;
    }
  }
}

.skin7 {
  .application-details {
    flex-direction: column;
    align-items: center;

    .app-criteria {
      width: 100% !important;
    }

    .app-criteria-bubble-chart {
      margin-bottom: 64px;
      height: 550px;
      text-align: center;
    }
  }
}

.skin6-products {
  z-index: 2;
}

.skin5-products {
  margin-top: 32px;
}

.app-criteria-top-slider {

}

.app-criteria-field {
  margin-bottom: 16px;
}