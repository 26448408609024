
.arc {
  position: absolute;
  width: 2200px;
  height: 2200px;
  border-radius: 50%;
  border: 1px solid;

  .thumb-container {
    left: -15px;
  }
}

.arc2 {
  position: absolute;
  width: 2315px;
  height: 2315px;
  border-radius: 50%;
  border: 1px solid;

  .thumb-container {
    left: -30px;
  }
}


.arc3 {
  position: absolute;
  width: 2430px;
  height: 2430px;
  border-radius: 50%;
  border: 1px solid;

  .thumb-container {
    left: -45px;
  }
}

.arc_start {
  position: absolute;
  border-color: transparent transparent transparent grey transparent;
  transform: rotate(90deg) translateX(-37%) translateY(1%);
  z-index: 1;
  top: 900px;
  display: flex;
  align-items: center;

  .thumb-container {
    transform: rotate(-90deg);
    position: relative;

    & > svg {
      margin-right: 5px;
    }
  }
}

.importance-bar {
  height: 3px;
  background-color: #dbf26e;
  background-image: linear-gradient(319deg, #dbf26e 0%, #61fa74 37%, #1cfdd6 100%);
  margin-top: -8px;
  align-self: flex-start;
  z-index: 2;
}

.draggable-div-actions {
  margin: -55px auto 20px;
  text-align: center;
  z-index: 10;
  position: relative;

  .decide-btn {
    margin: 0 150px;
    padding-left: 60px;
    padding-right: 60px;
  }
}

.app-criteria-card-item {
  width: 200px;
  height: 250px;
  border-radius: 5px;
  display: flex;
  padding-top: 8px;
  flex-direction: column;
  align-items: center;
  user-select: none;
  margin-left: -20px;
  overflow: hidden;
  background-color: white;

  .importance-card-title {
    border-bottom: 1px solid grey;

    & svg {
      fill: black;
    }
  }
}

.app-criteria-icon {
  margin: 20px auto;
  border-radius: 5px;
  width: 60%;
  user-select: none;
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}