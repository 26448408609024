@import '../../variables';

.virtual-agent {
  width: 90%;
  max-width: 1600px;
  margin: 0 auto 32px;

  h1 {
    text-align: center;
  }
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

//body {
//  font-family: Arial, sans-serif;
//  background-color: #f5f5f5;
//  padding: 24px;
//}

.main {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.actionRowsWrap {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.actionRow {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
}

.actionRow label {
  display: flex;
  align-items: center;
  gap: 8px;
}

button {
  display: inline-block;
  padding: 0 16px;
  border-radius: 8px;
  height: 40px;

  background-color: #4caf50; /* Green */
  border: none;

  color: #fff;
  text-align: center;
  font-size: 16px;

  cursor: pointer;
  transition-duration: 0.4s;
}

button.start-button {
  background-color: #24a0ed;
}

button.start-button:hover {
  background-color: #24A0EDDD;
}

input {
  height: 40px;
  padding: 0 12px;

  font-size: 16px;
}

#status {
  overflow: auto;

  background-color: #fff;
  height: 120px;
  padding: 10px 12px;

  border: 1px solid #ccc;
  border-radius: 8px;

  font-size: 14px;
  line-height: 1.6;
}

.videoSectionWrap {
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
  transition: all 0.5s ease;
  margin: auto;
  width: 100%;
  min-width: 400px;
  max-width: 550px;


  &.contains-options {
    justify-content: space-between;
  }
}

.actionRow.switchRow {
  width: 100%;

  justify-content: center;
}

.switchRow {
  flex-direction: column;
}

.switchRow > label {
  width: 100%;

  display: flex;
  justify-content: center;
}

.switchRow > label input {
  flex: 1;
  max-width: 500px;
}

.videoSectionWrap .videoWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease;
  width: 400px;

  /*background: linear-gradient(0deg, rgba(0, 0, 0, 0.02) 0%, rgba(0, 0, 0, 0.02) 100%),*/
  /*  radial-gradient(*/
  /*    108.09% 141.42% at 0% 100%,*/
  /*    rgba(219, 255, 213, 0.3) 0%,*/
  /*    rgba(255, 255, 255, 0) 100%*/
  /*  ),*/
  /*  linear-gradient(135deg, #ffeede 5.71%, #ffd9d9 47%, #a3dce7 93.47%);*/
}

.videoWrap .videoEle {
  width: 100%;
  max-height: 400px;
}

/*---------- Switch START ----------*/
.switchWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/*---------- Switch END ----------*/

.videoSectionWrap .hide {
  display: none;
}

.videoSectionWrap .show {
  display: flex;
}

.hide {
  display: none;
}

.show {
  display: flex;
}

#avatarID, #voiceID {
  display: none;
}

.error-message {
  color: #f43838;
}

.question-options {
  text-align: center;
  list-style-type: none;
  width: 250px;
  overflow: hidden;
  word-wrap: break-word;
  transition: all 0.5s ease;
  height: 100%;

  &.hide {
    display: none;
    height: 0;
  }

  li {
    width: fit-content;
    font-size: 14px;
    margin-bottom: 4px;
    font-weight: normal;
  }
}