.swiper-container {
  position: relative;

  &.swiper-horizontal {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .swiper-wrapper {
    position: relative;

    .swiper-slide {
      width: 302px !important;
    }
  }

  .swiper-button-prev {
    left: -200px;
  }

  .swiper-button-next {
    right: -200px;
  }
}

.swiper-main-container {
  position: relative;

  .custom-navigation-button {
    cursor: pointer;
  }

  .swiper-button-prev.custom-navigation-button.custom-navigation-button-prev {
    position: absolute;
    left: -50px;

    @media screen and (max-width: 600px) {
      left: -32px;
    }
  }

  .swiper-button-next.custom-navigation-button.custom-navigation-button-next {
    position: absolute;
    right: -50px;

    @media screen and (max-width: 600px) {
      right: -32px;
    }
  }
}