.product-cart {
  border: 1px solid #ccc;
  border-radius: 3px;
  transition: box-shadow 0.3s ease;
  width: 300px;
  display: flex;
  align-items: flex-start;

  &:hover {
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
  }

  .product-image {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    width: 150px;
    height: 120px;
    cursor: pointer;
  }

  .product-details {
    padding: 8px;
    word-wrap: break-word;
    overflow: hidden;

    .product-name {
      margin: 0 0 4px 0;
      cursor: pointer;
    }

    .product-description {
      margin: 0;
      color: #7d7d7d;
    }
  }
}
