.app {
}

.app-header {
  font-size: 32px;
  color: #282c34;
  margin: 20px;
  font-weight: bold;
}

.app-subtitle {
  color: black;
  margin-left: 20px;
}
