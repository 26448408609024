.applications {
  width: 80%;
  max-width: 1200px;
  margin: 0 auto 32px;

  .content-header {
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 32px auto;

    @media screen and (max-width: 768px) {
      flex-direction: column-reverse;
    }

    .decisions-types {
      display: flex;
      flex-wrap: wrap;

      span {
        font-size: 14px;
        background-color: #fff;
        border: 1px rgb(218, 220, 224) solid;
        border-radius: 9999px;
        box-sizing: border-box;
        cursor: pointer;
        transition: background .2s .1s;
        padding: 6px 16px;
        margin-right: 8px;
        margin-bottom: 8px;

        &:hover {
          background-color: #e0e2e3;
        }

        &.disabled {
          opacity: .5;
          background-color: #afaaaa;
          cursor: default;
        }
      }
    }

    .search {
      width: 300px;
      padding: 8px;
      height: 32px;
      border: 1px solid #ccc;
      border-radius: 5px;

      @media screen and (max-width: 768px) {
        width: 100%;
        margin-bottom: 8px;
      }
    }
  }

  .decisions {
    .applications-skeleton-title {
      margin-bottom: 8px;
    }

    .applications-skeleton-wrapper {
      display: inline-block;
      width: calc(25% - 16px);
      margin-right: 16px;
      margin-bottom: 32px;
    }
  }
}