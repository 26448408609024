.new-app-results-container {

  .no-results {
    color: #676767;
  }

  .new-app-results {
    .threeColumns {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;

      .new-results-item {
        margin-right: 20px;
        height: unset;
        margin-bottom: 32px;
        width: 31%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: hidden;
        position: relative;

        .image-container {
          width: 100%;
          height: 250px;
          border-top-left-radius: 3px;
          border-bottom-left-radius: 0;
          border-top-right-radius: 3px;
          background-size: cover;
          background-position: center;
        }

        .new-results-item-details {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;

          &-rank {
            margin-left: -16px;

            &-bar {
              height: 3px;
              background-color: #dbf26e;
              background-image: linear-gradient(319deg, #dbf26e 0%, #61fa74 37%, #1cfdd6 100%);
            }

            &-rate {
              text-align: right;
              font-weight: bold;
              padding-top: 8px;
              padding-right: 16px;
              font-size: 24px;
            }
          }

          h3 {
            margin-top: -25px;
            width: 75%;

            &.no-margin {
              margin-top: 0;
            }
          }

          &-specs {
            &-spec {
              width: 90%;
              max-width: 350px;
              display: flex;
              justify-content: space-between;
              border-bottom: 1px solid transparent;
            }
          }

          &-ctas {
            width: calc(100% - 16px);
            display: flex;
            justify-content: flex-start;
            flex-direction: row-reverse;
            padding: 16px 0;
            position: static;

            &-cta {
              margin: 0;
              width: fit-content;
              cursor: pointer;
              background-color: white;

              &:nth-child(2) {
                margin-right: 8px;
              }

              span {
                margin: 0;
              }
            }
          }
        }
      }
    }
  }

  .new-results-item {
    height: 260px;
    display: flex;
    border-radius: 3px;
    outline: 1px solid grey;
    margin-bottom: 16px;
    overflow: hidden;
    position: relative;

    .image-container {
      width: 350px;
      height: 100%;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      background-size: cover;
      background-position: center;
    }

    .new-results-item-details {
      flex: 1;
      margin-left: 16px;

      &-rank {
        margin-left: -16px;

        &-bar {
          height: 3px;
          background-color: #dbf26e;
          background-image: linear-gradient(319deg, #dbf26e 0%, #61fa74 37%, #1cfdd6 100%);
        }

        &-rate {
          text-align: right;
          font-weight: bold;
          padding-top: 8px;
          padding-right: 16px;
          font-size: 24px;
        }
      }

      &-ctas {
        width: calc(100% - 16px);
        display: flex;
        justify-content: flex-start;
        flex-direction: row-reverse;
        padding: 0 16px 16px 0;
        position: absolute;
        right: 0;
        bottom: 0;

        &-cta {
          background-color: white;
          cursor: pointer;

          &:nth-child(2) {
            margin-right: 8px;
          }

          span {
            margin: 0;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1300px) and (min-width: 800px) {
    .app-results {
      .threeColumns {
        .new-results-item {
          width: 46% !important;
        }
      }
    }
  }

  @media screen and (max-width: 800px) {
    width: 100% !important;
    padding: 0 16px;
    margin-bottom: 100px !important;

    .application-details {
      flex-direction: column;

      .app-criteria {
        width: 100% !important;
        margin-bottom: 20px;
      }

      .app-results {
        width: 100% !important;
        padding-left: 0 !important;

        .new-results-item {
          flex-direction: column;
          height: 400px !important;

          & > .image-container {
            width: 100%;
          }
        }

        .threeColumns {
          .new-results-item {
            width: 100% !important;
            margin-right: 0 !important;
          }
        }
      }
    }
  }

  .app-results-page-info {
    //text-align: right;
    font-size: 16px;
  }
}

.new-results-product-specs {
  &-spec {
    width: 90%;
    max-width: 350px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid transparent;

    .multi-spec-item {
      white-space: nowrap;
    }

    &-colors {
      display: flex;
      justify-content: flex-end;
      height: 100%;

      .new-results-product-specs-spec-color {
        margin-left: 4px;
      }
    }

    &-color {
      border: 1px solid gray;
      border-radius: 3px;
      width: 20px;
      height: 100%;
    }
  }
}
