.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #f8f9fa;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 999;

  &__logo {
    cursor: pointer;
    img {
      width: 150px;
    }
  }

  &__nav {
    display: flex;
    gap: 15px;

    @media (max-width: 768px) {
      display: none;
      flex-direction: column;
      background-color: #f8f9fa;
      position: absolute;
      top: 40px; // Adjust based on your header height
      left: 0;
      width: 100%;
      padding: 10px 20px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      z-index: 999;

      &.active {
        display: flex;
      }
    }
  }

  &__link {
    text-decoration: none;
    color: #333;
    font-size: 1rem;
    padding: 5px 10px;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;

    &:hover {
      background-color: #e2e6ea;
      color: #000;
    }

    &:last-child::after {
      content: '';
    }

    @media (max-width: 768px) {
      margin-bottom: 10px;
      width: 100%;
      text-align: left;
      &::after {
        content: '';
      }
    }
  }

  &__buttons {
    display: flex;
    gap: 10px;

    .user-info {
      padding: 5px 15px;
      font-size: 1rem;
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  &__button {
    padding: 5px 15px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;

    &--signup {
      background-color: transparent;
      color: #007bff;
      border: 1px solid #007bff;
      cursor: pointer;
      text-decoration: none;

      &:hover {
        background-color: #007bff;
        color: #fff;
      }
    }

    &--login {
      background-color: #007bff;
      color: #fff;
      cursor: pointer;
      text-decoration: none;

      &:hover {
        background-color: #0056b3;
      }
    }

    &--logout {
      background-color: transparent;
      color: #007bff;
      border: 1px solid #007bff;
      cursor: pointer;
      text-decoration: none;

      &:hover {
        background-color: #007bff;
        color: #fff;
      }
    }
  }

  &__menu-icon {
    display: none;
    font-size: 1.5rem;
    cursor: pointer;
    user-select: none;

    @media (max-width: 768px) {
      display: block;
    }
  }
}
