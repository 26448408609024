.application {
  width: 90%;
  max-width: 1600px;
  margin: 0 auto 32px;

  .warning-banner {
    padding: 10px;
    background-color: #f5d69e;
    margin-bottom: 16px;
  }

  .application-data {
    display: flex;
    margin-bottom: 32px;

    img {
      width: 250px;
    }

    .app-info {
      margin-left: 16px;

      .app-name {
        margin-bottom: 8px;
      }

      .app-description {
        color: #676767;
      }
    }
  }

  .application-details {
    display: flex;

    .app-criteria {
      width: 25%;

      .app-criteria-actions {
        margin-top: 8px;
        display: flex;
        justify-content: space-between;

        .app-criteria-actions-action {
          width: 49%;
        }
      }
    }

    .app-results {
      width: 75%;
      padding-left: 32px;

      .results-skin4 {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;

        .app-results-item {
          margin-right: 20px;
          height: unset;
          margin-bottom: 32px;
          width: 31%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          overflow: hidden;

          .image-container {
            width: 100%;
            height: 250px;
            border-top-left-radius: 3px;
            border-bottom-left-radius: 0;
            border-top-right-radius: 3px;
            background-size: cover;
            background-position: center;
          }

          .app-criteria-item-details {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            &-rank {
              margin-left: -16px;

              &-bar{
                height: 3px;
                background-color: #dbf26e;
                background-image: linear-gradient(319deg, #dbf26e 0%, #61fa74 37%, #1cfdd6 100%);
              }

              &-rate {
                text-align: right;
                font-weight: bold;
                padding-top: 8px;
                padding-right: 16px;
                font-size: 24px;
              }
            }

            h3 {
              margin-top: -25px;
            }

            &-specs {
              &-spec {
                width: 90%;
                max-width: 350px;
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid transparent;

                & > span:last-child {
                  text-align: right;
                }
              }
            }

            &-ctas {
              width: calc(100% - 16px);
              display: flex;
              justify-content: flex-end;
              padding: 16px 0;

              &-cta {
                margin: 0;
                width: fit-content;

                span {
                  margin: 0;
                }
              }
            }
          }
        }
      }

      .app-results-item {
        height: 260px;
        display: flex;
        border-radius: 3px;
        outline: 1px solid grey;
        margin-bottom: 16px;

        .image-container {
          width: 350px;
          height: 100%;
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
          background-size: cover;
          background-position: center;
        }

        .app-criteria-item-details {
          flex: 1;
          margin-left: 16px;

          &-rank {
            margin-left: -16px;
            &-bar{
              height: 3px;
              background-color: #dbf26e;
              background-image: linear-gradient(319deg, #dbf26e 0%, #61fa74 37%, #1cfdd6 100%);
            }

            &-rate {
              text-align: right;
              font-weight: bold;
              padding-top: 8px;
              padding-right: 16px;
              font-size: 24px;
            }
          }

          &-specs {
            //width: 150px;

            &-spec {
              width: 80%;
              max-width: 350px;
              display: flex;
              justify-content: space-between;
              border-bottom: 1px solid transparent;
            }
          }

          &-ctas {
            width: calc(100% - 16px);
            display: flex;
            justify-content: flex-end;
            padding: 0 16px 16px 0;

            &-cta {
              margin-left: 16px;
            }
          }
        }
      }

      .app-results-page-info {
        //text-align: right;
        font-size: 16px;
      }
    }
  }

  .product-custom-header {
    width: 75%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 16px;
  }
}