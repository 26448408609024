.mobile-application.application {
  .application-data {
    padding-top: 8px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 8px;

    & > img {
      width: 64px !important;
    }

    .app-info {
      .app-name {
        font-size: 18px;
        width: fit-content;
      }

      .app-description {
font-size: 14px;
      }
    }
  }

  .application-details {
    .app-results {
      .app-results-item {
        flex-direction: row;
        height: 110px !important;

        .image-container {
          width: 120px;
        }

        .app-criteria-item-details {
          display: flex;
          justify-content: space-between;
          flex-direction: column;

          .app-criteria-item-details-rank-rate {
            padding: 8px;
            display: flex;
            font-size: 18px;
            justify-content: space-between;

            & > span {
              text-align: left;
              max-width: 200px;
              overflow: hidden;
              white-space: nowrap;
            }
          }

          .app-criteria-item-details-ctas {
            padding: 0 0 8px;
          }
        }
      }
    }
  }
}